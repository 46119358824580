.app-article-list {
  .wrapper {
    position: relative;
    .btn-new-article {
      position: absolute; } }

  .message-card {
    .card {
      margin: .5rem 0;
      background: #FFFAFA; } } }
