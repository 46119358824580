@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
.refund-list .btn-group {
  display: block;
  width: 100%;
  margin-top: 1rem;
  margin-left: 8px; }

.table-toolbar-btn-group {
  margin: auto 1rem auto 0; }

.app-article-list .wrapper {
  position: relative; }
  .app-article-list .wrapper .btn-new-article {
    position: absolute; }

.app-article-list .message-card .card {
  margin: .5rem 0;
  background: #FFFAFA; }

.app-article-new .paper, .app-article-edit .paper {
  padding-top: 0; }

.app-article-new .mde-wrapper, .app-article-edit .mde-wrapper {
  margin: 16px 8px 8px 8px; }

.app-article-new .btn-upload-wrapper, .app-article-edit .btn-upload-wrapper {
  margin: 8px 8px 8px 8px; }

.app-article-new .toggle-wrapper, .app-article-edit .toggle-wrapper {
  margin: 16px 8px 0px 8px; }
  .app-article-new .toggle-wrapper .label, .app-article-edit .toggle-wrapper .label {
    font-size: .75rem; }

.app-article-new .cover-photo .tool-bar, .app-article-edit .cover-photo .tool-bar {
  display: -webkit-flex;
  display: flex;
  background: black;
  padding: .25rem .5rem; }
  .app-article-new .cover-photo .tool-bar .icon, .app-article-new .cover-photo .tool-bar .text, .app-article-edit .cover-photo .tool-bar .icon, .app-article-edit .cover-photo .tool-bar .text {
    color: red;
    cursor: pointer; }

.app-article-edit .init-loader {
  margin-top: 16px; }

.notify .success-msg {
  text-align: center; }
  .notify .success-msg .icon {
    font-size: 80px;
    color: #28a745;
    margin-top: 1rem;
    margin-bottom: .5rem; }
  .notify .success-msg .text {
    font-weight: bolder; }
  .notify .success-msg a {
    margin-top: 1rem; }

.sponsor-product-new .btn-upload-wrapper {
  margin: 16px 0 8px 0; }

.sponsor-product-new .cover-photo {
  margin: 16px 8px 8px 8px; }
  .sponsor-product-new .cover-photo .tool-bar {
    display: -webkit-flex;
    display: flex;
    background: black;
    padding: .25rem .5rem; }
    .sponsor-product-new .cover-photo .tool-bar .icon, .sponsor-product-new .cover-photo .tool-bar .text {
      color: red;
      cursor: pointer; }

.sponsor-product-connect .error {
  color: #d32f2f;
  margin: 16px 8px 8px 8px;
  display: -webkit-flex;
  display: flex; }
  .sponsor-product-connect .error strong {
    margin-left: .25rem; }

.notification-success-page {
  text-align: center; }
  .notification-success-page .icon {
    font-size: 80px;
    color: #28a745;
    margin-top: 1rem;
    margin-bottom: .5rem; }
  .notification-success-page .text {
    font-weight: bolder; }
  .notification-success-page a {
    margin-top: 1rem; }

.mde-header {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  border-bottom: 1px solid #c8ccd0;
  border-radius: 2px 2px 0 0;
  background: #f9f9f9; }
  .mde-header .mde-tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .mde-header .mde-tabs button {
      border-radius: 2px;
      margin: 6px 3px;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer; }
      .mde-header .mde-tabs button:first-child {
        margin-left: 6px; }
      .mde-header .mde-tabs button.selected {
        border: 1px solid #c8ccd0; }
  .mde-header .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -.125em; }
  .mde-header button:focus {
    outline: 0; }
  .mde-header ul.mde-header-group {
    margin: 0;
    padding: 10px;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .mde-header ul.mde-header-group.hidden {
      visibility: hidden; }
    .mde-header ul.mde-header-group li.mde-header-item {
      display: inline-block;
      position: relative;
      margin: 0 4px; }
      .mde-header ul.mde-header-group li.mde-header-item button {
        text-align: left;
        cursor: pointer;
        height: 22px;
        padding: 4px;
        margin: 0;
        border: none;
        background: none;
        color: #242729; }

@-webkit-keyframes tooltip-appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes tooltip-appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::before {
          -webkit-animation-name: tooltip-appear;
                  animation-name: tooltip-appear;
          -webkit-animation-duration: 0.2s;
                  animation-duration: 0.2s;
          -webkit-animation-delay: 0.5s;
                  animation-delay: 0.5s;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;
          opacity: 0;
          position: absolute;
          z-index: 1000001;
          width: 0;
          height: 0;
          color: rgba(0, 0, 0, 0.8);
          pointer-events: none;
          content: "";
          border: 5px solid transparent;
          top: -5px;
          right: 50%;
          bottom: auto;
          margin-right: -5px;
          border-top-color: rgba(0, 0, 0, 0.8); }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::after {
          -webkit-animation-name: tooltip-appear;
                  animation-name: tooltip-appear;
          -webkit-animation-duration: 0.2s;
                  animation-duration: 0.2s;
          -webkit-animation-delay: 0.5s;
                  animation-delay: 0.5s;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;
          font-size: 11px;
          opacity: 0;
          position: absolute;
          z-index: 1000000;
          padding: 5px 8px;
          color: #fff;
          pointer-events: none;
          content: attr(aria-label);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 3px;
          right: 50%;
          bottom: 100%;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%);
          margin-bottom: 5px;
          white-space: nowrap; }
      .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown {
        position: absolute;
        left: 0;
        top: 30px;
        background-color: white;
        border: 1px solid #c8ccd0;
        padding: 5px;
        z-index: 2;
        -webkit-transform: translateX(-9px);
                transform: translateX(-9px); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li {
          margin: 0;
          white-space: nowrap;
          list-style: none;
          display: block; }
          .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button {
            display: block;
            height: auto; }
            .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p {
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              line-height: 1em;
              background: none;
              border: 0;
              text-align: left; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p:hover {
                color: #4078c0; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-1 {
                font-size: 20px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-2 {
                font-size: 18px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-3 {
                font-size: 14px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-4 {
                font-size: 12px; }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.15);
          top: -16px;
          left: 3px;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-bottom-color: white;
          top: -14px;
          left: 5px;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%); }

textarea.mde-text {
  width: 100%;
  border: 0;
  padding: 10px;
  vertical-align: top;
  resize: none;
  overflow-y: auto; }

.mde-preview .mde-preview-content {
  padding: 10px; }
  .mde-preview .mde-preview-content p, .mde-preview .mde-preview-content blockquote, .mde-preview .mde-preview-content ul, .mde-preview .mde-preview-content ol, .mde-preview .mde-preview-content dl, .mde-preview .mde-preview-content table, .mde-preview .mde-preview-content pre {
    margin-top: 0;
    margin-bottom: 16px; }
  .mde-preview .mde-preview-content h1, .mde-preview .mde-preview-content h2, .mde-preview .mde-preview-content h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.3em; }
  .mde-preview .mde-preview-content h1 {
    font-size: 1.6em; }
  .mde-preview .mde-preview-content h2 {
    font-size: 1.4em; }
  .mde-preview .mde-preview-content h3 {
    font-size: 1.2em; }
  .mde-preview .mde-preview-content ul, .mde-preview .mde-preview-content ol {
    padding-left: 2em; }
  .mde-preview .mde-preview-content blockquote {
    margin-left: 0;
    padding: 0 1em;
    color: #777;
    border-left: 0.25em solid #ddd; }
    .mde-preview .mde-preview-content blockquote > :first-child {
      margin-top: 0; }
    .mde-preview .mde-preview-content blockquote > :last-child {
      margin-bottom: 0; }
  .mde-preview .mde-preview-content code {
    padding: 0.2em 0 0.2em 0;
    margin: 0;
    font-size: 90%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px; }
    .mde-preview .mde-preview-content code::before, .mde-preview .mde-preview-content code::after {
      letter-spacing: -0.2em;
      content: "\A0"; }
  .mde-preview .mde-preview-content pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border-radius: 3px; }
    .mde-preview .mde-preview-content pre code {
      display: inline;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0; }
      .mde-preview .mde-preview-content pre code::before, .mde-preview .mde-preview-content pre code::after {
        content: none; }
    .mde-preview .mde-preview-content pre > code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0; }
  .mde-preview .mde-preview-content a {
    color: #4078c0;
    text-decoration: none; }
    .mde-preview .mde-preview-content a:hover {
      text-decoration: underline; }
  .mde-preview .mde-preview-content > *:first-child {
    margin-top: 0 !important; }
  .mde-preview .mde-preview-content > *:last-child {
    margin-bottom: 0 !important; }
  .mde-preview .mde-preview-content::after {
    display: table;
    clear: both;
    content: ""; }
  .mde-preview .mde-preview-content table {
    display: block;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse; }
    .mde-preview .mde-preview-content table thead th {
      font-weight: bold; }
    .mde-preview .mde-preview-content table th, .mde-preview .mde-preview-content table td {
      padding: 6px 13px;
      border: 1px solid #c8ccd0; }

* {
  box-sizing: border-box; }

.react-mde {
  border: 1px solid #c8ccd0;
  border-radius: 2px; }
  .react-mde .grip {
    border-top: 1px solid #c8ccd0;
    background-color: #f9f9f9;
    text-align: center;
    height: 10px;
    color: black;
    cursor: s-resize; }
    .react-mde .grip .icon {
      height: 10px; }

* {
  font-family: 'Noto Sans TC'; }

.paper {
  padding: 16px; }

.auth-fail {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 16px; }
  .auth-fail .icon {
    margin-right: .25rem; }
  .auth-fail .text {
    font-weight: bold; }

.copyable:hover {
  cursor: pointer;
  border-bottom: 1px dotted; }

