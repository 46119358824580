@import "variables.scss";

.mde-header {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid $mde-border-color;
  border-radius: $mde-border-radius $mde-border-radius 0 0;
  background: $mde-toolbar-color;

  .mde-tabs {
    display: flex;
    flex-direction: row;

    button {
      border-radius: $mde-border-radius;
      margin: 6px 3px;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      &:first-child {
        margin-left: 6px;
      }
      &.selected {
        border: 1px solid $mde-border-color
      }
    }
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -.125em;
  }

  button:focus {
    outline: 0;
  }

  ul.mde-header-group {
    margin: 0;
    padding: $mde-toolbar-padding;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;

    &.hidden {
      visibility: hidden;
    }

    li.mde-header-item {
      display: inline-block;
      position: relative;
      margin: 0 4px;
      button {
        text-align: left;
        cursor: pointer;
        height: 22px;
        padding: 4px;
        margin: 0;
        border: none;
        background: none;
        color: $mde-button-color;
        @keyframes tooltip-appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @mixin tooltip-animation {
          animation-name: tooltip-appear;
          animation-duration: 0.2s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
        }
        &.tooltipped {
          &:hover::before {
            @include tooltip-animation();
            opacity: 0;
            position: absolute;
            z-index: 1000001;
            width: 0;
            height: 0;
            color: rgba(0, 0, 0, 0.8);
            pointer-events: none;
            content: "";
            border: 5px solid transparent;
            top: -5px;
            right: 50%;
            bottom: auto;
            margin-right: -5px;
            border-top-color: rgba(0, 0, 0, 0.8);
          }
          &:hover::after {
            @include tooltip-animation();
            font-size: 11px;
            opacity: 0;
            position: absolute;
            z-index: 1000000;
            padding: 5px 8px;
            color: #fff;
            pointer-events: none;
            content: attr(aria-label);
            background: rgba(0, 0, 0, 0.8);
            border-radius: 3px;
            right: 50%;
            bottom: 100%;
            transform: translateX(50%);
            margin-bottom: 5px;
            white-space: nowrap;
          }
        }

      }
      ul.react-mde-dropdown {
        position: absolute;
        left: 0;
        top: 30px;
        background-color: white;
        border: 1px solid $mde-border-color;
        padding: 5px;
        z-index: 2;
        transform: translateX(-9px);
        li {
          margin: 0;
          white-space: nowrap;
          list-style: none;
          display: block;
          button {
            display: block;
            height: auto;
            p {
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              line-height: 1em;
              background: none;
              border: 0;
              text-align: left;
              &:hover {
                color: #4078c0;
              }
              &.header-1 {
                font-size: 20px;
              }
              &.header-2 {
                font-size: 18px;
              }
              &.header-3 {
                font-size: 14px;
              }
              &.header-4 {
                font-size: 12px;
              }
            }
          }

        }
        &::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.15);
          top: -16px;
          left: 3px;
          transform: translateX(50%);
        }
        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-bottom-color: white;
          top: -14px;
          left: 5px;
          transform: translateX(50%);
        }
      }
    }
  }
}

