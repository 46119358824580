@import "../variable";

.sponsor-product-connect {
    .error {
        color: $danger-color;
        margin: 16px 8px 8px 8px;
        display: flex;
        strong {
            margin-left: .25rem; } } }

