.app-article-new, .app-article-edit {
  .paper {
    padding-top: 0; }
  .mde-wrapper {
    margin: 16px 8px 8px 8px; }
  .btn-upload-wrapper {
    margin: 8px 8px 8px 8px; }
  .toggle-wrapper {
    margin: 16px 8px 0px 8px;
    .label {
      font-size: .75rem; } }
  .cover-photo {
    .tool-bar {
      display: flex;
      background: black;
      padding: .25rem .5rem;
      .icon, .text {
        color: red;
        cursor: pointer; } } } }

.app-article-edit {
  .init-loader {
    margin-top: 16px; } }
