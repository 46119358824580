//General

$mde-border-radius:                 2px !default;
$mde-border-color:                  #c8ccd0 !default;
$mde-button-color:                  #242729 !default;
$mde-toolbar-color:                 #f9f9f9 !default;
$mde-toolbar-padding:               10px !default;
$mde-editor-default-min-height:     200px !default;
$mde-editor-padding:                10px !default;
$mde-preview-horizontal-padding:    10px !default;
$mde-preview-padding:               10px !default;
$mde-preview-default-min-height:    $mde-editor-default-min-height !default;
$mde-preview-default-height:        auto !default;
