.sponsor-product-new {
  .btn-upload-wrapper {
    margin: 16px 0 8px 0; }
  .cover-photo {
    margin: 16px 8px 8px 8px;
    .tool-bar {
      display: flex;
      background: black;
      padding: .25rem .5rem;
      .icon, .text {
        color: red;
        cursor: pointer; } } } }
