@import "../variable";

.notify {
  .success-msg {
    text-align: center;
    .icon {
      font-size: 80px;
      color: $success-color;
      margin-top: 1rem;
      margin-bottom: .5rem; }
    .text {
      font-weight: bolder; }
    a {
      margin-top: 1rem; } } }
