@import "./refund/list";
@import "./refund/refund";

@import "./article/list";
@import "./article/article";

@import "./member/notify";

@import "./sponsorProduct/new";
@import "./sponsorProduct/connect";

@import "./notification/successPage";

// react markdown editor
@import "./react-mde/react-mde-all";

// 思源字體
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);

// 全局使用思源字體
* {
  font-family: 'Noto Sans TC'; }

// Material-UI Paper padding
.paper {
  padding: 16px; }

// App.js Line Auth Fail
.auth-fail {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .icon {
    margin-right: .25rem; }
  .text {
    font-weight: bold; } }

// For Copyable Text
.copyable {
  &:hover {
    cursor: pointer;
    border-bottom: 1px dotted; } }
